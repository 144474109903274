import ReactDOM from 'react-dom/client';

// replaceHydrateFunction fixes these React errors in production:
// #418 Hydration failed because the initial UI does not match what was rendered on the server.
// #423 There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root will switch to client rendering.
export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};
